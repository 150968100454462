export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/chirismomodelight-logo.jpg',
    banner_img_root : '/img/chirismomodelight-logo.jpg',
    resid : '',
    key_value : 'ladistileria',
    secret_value : 'ladistileria',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12237.753675929254!2d-105.089228!3d39.931582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbc276b7cf9f194c0!2sLa%20Distileria!5e0!3m2!1sen!2sin!4v1659436746950!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Ladistileria Order Online"
};
